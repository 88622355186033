<template>
  <div class="integral-page-view">
    <div class="main-cont">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mt20 mb20">
        <el-breadcrumb-item :to="{ path: '/pointsMall' }">{{
          lang_data == 1 ? "积分商城" : "Integral mall"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "兑换" : "Exchange"
        }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div
        class="exchange-panel"
        v-loading="loading"
        element-loading-text="Loading"
      >
        <div class="goods-info-group" v-if="detail">
          <div class="banner-list">
            <div
              class="banner-list-item"
              :class="{ checked: currentBannerIndex == index }"
              v-for="(item, index) in bannerList"
              :key="item"
              @mouseover="currentBannerIndex = index"
            >
              <img v-if="item" :src="item" alt="" />
            </div>
          </div>

          <div class="banner-preview">
            <img v-if="currentBanner" :src="currentBanner" alt="" />
          </div>

          <div class="goods-info">
            <div class="goods-title">
              <b>{{ detail.name }}</b>
            </div>
            <div class="goods-price">
              <span class="red-price">{{ detail.integral }}</span>
              <span>{{ lang_data == 1 ? "积分" : "integral" }}</span>
              <span class="old-price">￥{{ detail.reference_price }}</span>
              <span class="exchange-count"
                >{{ lang_data == 1 ? "已兑" : "Has the："
                }}{{ detail.exchange_num
                }}{{ lang_data == 1 ? "件" : "A" }}</span
              >
            </div>
            <div class="current-integral">
              {{ lang_data == 1 ? "可用积分：" : "Available integral：" }}
              <span style="color: #409eff"
                ><b>{{ info ? info.integral : 0 }}</b></span
              >
            </div>
            <div class="number-box exchange-count-group">
              <div
                class="sub"
                :class="{ disabled: isSubDisabled }"
                @click="onSubNumber"
              >
                <i
                  class="el-icon-minus"
                  style="color: #009ae3; font-size: 14px"
                ></i>
              </div>
              <div class="number-box-content">{{ form.count }}</div>
              <div
                class="add"
                :class="{ disabled: isAddDisabled }"
                @click="onAddNumber"
              >
                <!-- <u-icon name="plus" color="#FFFFFF" size="28"></u-icon> -->
                <i
                  class="el-icon-plus"
                  style="color: #ffffff; font-size: 14px"
                ></i>
              </div>
            </div>
            <div class="goods-leave-group">
              {{ lang_data == 1 ? "库存：" : "inventory："
              }}<span
                ><b
                  >{{ lang_data == 1 ? "库存" : "inventory "
                  }}{{ detail.stock_num
                  }}{{ lang_data == 1 ? "件    限购" : "A    For purchasing" }}
                  {{ detail.limit_buy_num }}A</b
                ></span
              >
            </div>
            <el-button
              type="primary"
              round
              class="exchange-btn"
              @click="goExchange"
              >{{
                lang_data == 1 ? "立即兑换" : "Immediately change"
              }}</el-button
            >
          </div>
        </div>

        <div class="goods-detail-header">
          <i class="el-icon-shopping-bag-1 mr5"></i
          >{{ lang_data == 1 ? "商品详情" : "Goods details" }}
        </div>

        <div class="goods-detail-content" v-html="detailContent"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      info: null,
      detail: null,

      currentBannerIndex: 0,

      form: {
        count: 1,
      },
      loading: true,
    };
  },
  computed: {
    currentBanner() {
      if (!this.detail) return "";
      //   return this.detail.main_image_url;
      return this.detail.image_urls[this.currentBannerIndex]
        ? this.detail.image_urls[this.currentBannerIndex]
        : "";
    },
    bannerList() {
      if (!this.detail) return [];
      return this.detail.image_urls;
    },
    isSubDisabled() {
      if (!this.detail) return false;
      if (this.form.count <= 1) return true;
      return false;
    },
    isAddDisabled() {
      if (!this.detail) return false;
      if (!this.checkAllow(this.form.count + 1)) return true;
      return false;
    },
    detailContent() {
      if (!this.detail) return "";
      return decodeURIComponent(this.detail.detail);
    },
  },
  mounted() {
    this.getUserInfo();
    this.getDetail();
  },
  methods: {
    getUserInfo() {
      this.$axios.get("/ucenter/getinfo", {}).then((res) => {
        if (res.code == 1) {
          this.info = res.data.doctor_info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    checkAllow(count, isShowMsg) {
      if (this.info) {
        let info = this.info;
        if (this.detail.integral * count > info.integral) {
          if (isShowMsg) {
            this.$message.warning(
              this.lang_data == 1 ? "积分不足" : "Lack of integral"
            );
          }
          return false;
        }
        if (count > this.detail.stock_num) {
          if (isShowMsg) {
            this.$message.warning(
              this.lang_data == 1 ? "库存不足" : "Lack of integral"
            );
          }
          return false;
        }
        if (
          this.detail.is_limit_buy.key == 1 &&
          count > this.detail.limit_buy_num
        ) {
          if (isShowMsg) {
            this.$message.warning(
              this.lang_data == 1
                ? "不能超过限购数"
                : "The purchase limit cannot be exceeded"
            );
          }
          return false;
        }
        return true;
      }
      return false;
    },
    onSubNumber() {
      if (this.form.count <= 1) return;
      this.form.count--;
    },
    onAddNumber() {
      // console.log('onAdd');
      if (!this.checkAllow(this.form.count + 1, true)) return;
      // console.log('onAddCheck');
      this.form.count++;
      // console.log('onAddCount');
    },
    getDetail() {
      this.loading = true;
      let params = {
        id: this.$route.query.goods_id,
      };
      this.$axios
        .get("/ucenter/integral_goods/getInfo", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$Message.error(res.msg);
          } else {
            this.detail = res.data;
          }
          this.loading = false;
        });
    },
    goExchange() {
      if (!this.checkAllow(this.form.count, true)) return;
      let goodsId = this.$route.query.goods_id;
      this.$axios
        .get("/ucenter/exchange_integral_goods/isAllowExchange", {
          params: {
            id: goodsId,
            exchange_num: this.form.count,
          },
        })
        .then((res) => {
          if (res.code == 1) {
            this.$router.push({
              path: "/confirmOrder",
              query: {
                goods_id: goodsId,
                count: this.form.count,
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100% - 70px);
  overflow: auto;
  .main-cont {
    width: 1300px;
    flex: 1;
    margin: 0 auto;
    padding-bottom: 30px;
    .status-card {
      width: 1300px;
      height: 82px;
      background: linear-gradient(135deg, #00adff 0%, #009ae3 100%);
      box-shadow: 0px 1px 5px 0px rgba(227, 228, 230, 0.48);
      border-radius: 8px 8px 0px 0px;
      margin-bottom: -10px;
      padding-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 0 20px 10px 20px;
    }
    .exchange-panel {
      width: 1300px;
      background: #ffffff;
      box-shadow: 0 0 12px 0#e4e4e4;
      border: 1px solid #e4e4e4;
      padding: 40px;
      border-radius: 8px;
      box-sizing: border-box;

      .goods-info-group {
        display: flex;

        .banner-list {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          .banner-list-item {
            width: 72px;
            height: 72px;
            border-radius: 4px;
            border: 1px solid transparent;
            background: #eee;
            margin-bottom: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            &.checked {
              border: 1px solid #009ae3;
            }
          }
        }
        .banner-preview {
          margin-left: 10px;
          width: 360px;
          height: 360px;
          border-radius: 4px;
          //   background: #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .goods-info {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          flex: 1;

          .goods-title {
            font-size: 20px;
          }
          .goods-price {
            margin-top: 10px;
            font-size: 12px;
            color: #606163;
            padding-bottom: 26px;
            border-bottom: 1px solid #e1e1e5;
            .red-price {
              font-size: 28px;
              font-weight: bold;
              color: #409eff;
              margin-right: 4px;
            }
            .old-price {
              font-size: 14px;
              color: #b0b1b5;
              text-decoration: line-through;
              margin-left: 20px;
            }
            .exchange-count {
              font-size: 14px;
              color: #b0b1b5;
              margin-left: 20px;
            }
          }
        }
        .current-integral {
          width: 100%;
          height: 44px;
          line-height: 44px;
          border-bottom: 1px solid #e1e1e5;
        }
        .exchange-count-group {
          margin-top: 82px;
        }
        .number-box {
          height: 100%;
          display: flex;
          .sub {
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #d5d5d5;
            border-radius: 12px;
            font-size: 20px;
            color: #009ae3;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .number-box-content {
            color: #000000;
            font-size: 14px;
            width: 39px;
            height: 24px;
            line-height: 24px;
            text-align: center;
          }
          .add {
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #009ae3;
            border-radius: 12px;
            font-size: 20px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .disabled {
            opacity: 0.6;
          }
        }
        .goods-leave-group {
          width: 100%;
          margin-top: 12px;
        }
        .exchange-btn {
          margin-top: 14px;
          width: 178px;
        }
      }

      .goods-detail-header {
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #f2f2f2;
        border-radius: 4px 4px 0px 0px;
        margin: 50px auto 0 auto;
        padding-left: 12px;
        font-size: 16px;
        color: #333;
        font-weight: 600;
      }
      .goods-detail-content {
        margin-top: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>